@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'styles/variables.less';
@import 'styles/texts.less';

/* You can add global styles to this file, and also import other style files */
//CUSTOM FONTS
@font-face {
  font-family: 'Nexa';
  src:
    url('./assets/fonts/NexaHeavy.woff2') format('woff2'),
    // Modern browsers
    url('./assets/fonts/NexaHeavy.otf') format('opentype'); // Fallback for older browsers
  font-display: fallback;
  font-weight: 900;
  font-style: normal;
  ascent-override: 85%;
  descent-override: 15%;
}

@font-face {
  font-family: 'Nexa';
  src:
    url('./assets/fonts/NexaBold.woff2') format('woff2'),
    // Modern browsers
    url('./assets/fonts/NexaBold.otf') format('opentype'); // Fallback for older browsers
  font-display: fallback;
  font-weight: bold;
  font-style: normal;
  ascent-override: 85%;
  descent-override: 15%;
}

@font-face {
  font-family: 'Nexa';
  src:
    url('./assets/fonts/NexaRegular.woff2') format('woff2'),
    // Modern browsers
    url('./assets/fonts/NexaRegular.otf') format('opentype'); // Fallback for older browsers
  font-display: fallback;
  font-weight: normal;
  font-style: normal;
  ascent-override: 85%;
  descent-override: 15%;
}

@font-face {
  font-family: 'Nexa';
  src:
    url('./assets/fonts/NexaLight.woff2') format('woff2'),
    // Modern browsers
    url('./assets/fonts/NexaLight.otf') format('opentype'); // Fallback for older browsers
  font-display: fallback;
  font-weight: 300;
  font-style: normal;
  ascent-override: 85%;
  descent-override: 15%;
}

@font-face {
  font-family: 'Nexa';
  src:
    url('./assets/fonts/NexaThin.woff2') format('woff2'),
    // Modern browsers
    url('./assets/fonts/NexaThin.otf') format('opentype'); // Fallback for older browsers
  font-display: fallback;
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src:
    url('./assets/fonts/NexaHeavyItalic.woff2') format('woff2'),
    // Modern browsers
    url('./assets/fonts/NexaHeavyItalic.otf') format('opentype'); // Fallback for older browsers
  font-display: fallback;
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Nexa';
  src:
    url('./assets/fonts/NexaBoldItalic.woff2') format('woff2'),
    // Modern browsers
    url('./assets/fonts/NexaBoldItalic.otf') format('opentype'); // Fallback for older browsers
  font-display: fallback;
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Nexa';
  src:
    url('./assets/fonts/NexaRegularItalic.woff2') format('woff2'),
    // Modern browsers
    url('./assets/fonts/NexaRegularItalic.otf') format('opentype'); // Fallback for older browsers
  font-display: fallback;
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Nexa';
  src:
    url('./assets/fonts/NexaLightItalic.woff2') format('woff2'),
    // Modern browsers
    url('./assets/fonts/NexaLightItalic.otf') format('opentype'); // Fallback for older browsers
  font-display: fallback;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Nexa';
  src:
    url('./assets/fonts/NexaThinItalic.woff2') format('woff2'),
    // Modern browsers
    url('./assets/fonts/NexaThinItalic.otf') format('opentype'); // Fallback for older browsers
  font-display: fallback;
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'DIN';
  src:
    url('./assets/fonts/DINCondensed-Bold.woff2') format('woff2'),
    // Modern browsers
    url('./assets/fonts/DINCondensed-Bold.otf') format('otf'); // Fallback for older browsers
  font-display: fallback;
  font-display: block;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}

@media print {
  @page {
    size: auto;
    /* auto is the initial value */
    /* this affects the margin in the printer settings */
    margin: 0;
  }

  * {
    -webkit-print-color-adjust: exact !important;
    /* Chrome, Safari */
    print-color-adjust: exact !important;
    /*Firefox*/
  }

  html,
  body {
    margin: 0 !important;

    /*Axeptio and freshwork*/
    #freshworks-container,
    #axeptio_overlay {
      display: none;
    }

    /*Axeptio and freshwork*/
  }

  .cdk-overlay-container {
    display: none;
  }
}

.container {
  width: 100%;
  max-width: 1200px;

  margin: auto;
  padding: 0 1.5rem;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;

  width: 100%;
  height: 18rem;

  background-color: var(--primary-500);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  display: block;
  width: 100vw;
  height: 100vh;

  background-color: transparent;
}

@screen xl {
  .container {
    max-width: 1200px;
  }
}

@screen 2xl {
  .container {
    max-width: 1600px;
  }
}