:root {
  --white: #ffffff;
  --black: #1f1f1f;
  --transparent: transparent;

  --primary-50: #F4F5FD;
  --primary-100: #DADEF7;
  --primary-200: #BEC4E8;
  --primary-400: #7F87B5;
  --primary-500: #465BD8;
  --primary-700: #354397;
  --primary-800: #0E153F;
  --primary-1000: #0E153F;
  --primary: var(--primary-500);
  --secondary-green-100: #E7F6F3;
  --secondary-green-200: #CEEDE7;
  --secondary-green-500: #5EC3AE;
  --secondary-green-1000: #3E8072;
  --secondary-red-100: #FFE7E7;
  --secondary-red-300: #FFE7E7;
  --secondary-red-500: #FF5F5F;
  --secondary-yellow-100: #F7F5CC;
  --secondary-yellow-300: #FEF1BD;
  --secondary-yellow-500: #776D25;
  --secondary-yellow-800: #776D25;


  --helper-black-800: #0E153F;

  --introspection: #5ec3ae;
  --impact: #001972;
  --equilibre: #fee95c;
  --ascension: #ee7737;
  --transformation: #901848;

  --mission: #497eff;
  --manager: #f9624d;
  --environment: #5ec3ae;

  --scotch-green: #85e3ac;
  --scotch-yellow: #fef851;
  --scotch-blue: #465bd8;
  --scotch-red: #f9624d;
}