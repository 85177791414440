@import 'colors.less';

:root {
  --topbar-height: 6.25rem;
  --bottombar-height: 5.25rem;

  --space-xs: 0.5rem;
  --space-sm: 0.75rem;

  --round-radius: 100%;
}
