// Text styles: Title
.title-xl {
  font-family: 'Din', sans-serif;
  font-size: 3.25rem;
  font-weight: bold;
  line-height: 1.6;
}

.title-lg {
  font-family: 'Nexa';
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.6;
}


/** Button/Button M */
.button-m {
  font-family: "Nexa";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@screen md {
  .title-lg {
    font-size: 2.8125rem;
  }
}

.title-lg-mobile {
  font-family: Nexa;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  text-align: left;
}

.title-md {
  font-family: 'Nexa';
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.25;
}

.title-sm {
  font-family: 'Nexa';
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.25;
}

.title-xs {
  font-family: 'Nexa';
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.25;
}

.title-xxs {
  font-family: 'Nexa';
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.3125rem;
}

// Text styles: Body
.body-lg {
  font-family: 'Nexa';
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.6;
}

.body-md {
  font-family: 'Nexa';
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
}

.body-sm {
  font-family: 'Nexa';
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
}

.body-xs {
  font-family: 'Nexa';
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
}

.caption-lg {
  font-family: 'Nexa';
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5;
}

// Text styles: Caption
.caption-md {
  font-family: 'Nexa';
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
}

.caption-sm {
  font-family: 'Nexa';
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.5;
}

.caption-xs {
  font-family: 'Nexa';
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1rem;
}

.button-m {
  font-family: 'Nexa';
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

// Text styles: CTA/Link
.link-md {
  font-family: 'Nexa';
  font-size: 1rem;
  font-weight: bold;
  line-height: normal;
}